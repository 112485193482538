import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'

import { TColumn, Table } from '../../../components'
import { RuleColumn } from '../../../components/Table/columns/RuleColumn'
import { ValueColumn } from '../../../components/Table/columns/ValueColumn'
import { WithDeleteColumn } from '../../../components/Table/columns/WithDeleteColumn'
import { CONTENT } from '../../../consts'
import { TAction, TRule } from '../../../types'

import styles from './ActionsTable.module.scss'

export type TActionsTableProps = {
    actions?: TAction[]
    deleteAction: (index: number) => void
    onRuleChange: (
        actionIndex: number,
        ruleIndex: number,
        value: boolean | TRule['expected'],
    ) => void
    rulesCount: number
    isLoading?: boolean
}

export const ActionsTable = ({
    actions,
    rulesCount,
    deleteAction,
    onRuleChange,
    isLoading,
}: TActionsTableProps) => {
    const COLUMNS: TColumn[] = [
        ValueColumn({
            title: CONTENT.ACTIONS.TABLE.ID,
            key: 'action-id',
            dataIndex: 'id',
            className: styles.IdColumn,
        }),
        WithDeleteColumn({
            title: CONTENT.ACTIONS.TABLE.NAME,
            key: 'action-name',
            dataIndex: 'name',
            width: '100%',
            deleteAction,
            className: styles.NameColumn,
        }),
        ValueColumn({
            title: CONTENT.ACTIONS.TABLE.ASSET_GROUP_ID,
            key: 'action-assetGroupId',
            dataIndex: 'assetGroupId',
            className: styles.GroupColumn,
        }),
    ]

    for (let x = 1; x <= rulesCount; x++) {
        COLUMNS.push(
            RuleColumn({
                index: x,
                key: `actions-rule`,
                titleClassName: styles.ColumnHeader,
                onRuleChange,
                renderCellContent: (value: boolean, onChange: (newValue: boolean) => void) => (
                    <div className={styles.Select}>
                        {value ? (
                            <CheckCircleOutlined
                                className={styles.Yes}
                                disabled={isLoading}
                                onClick={() => !isLoading && onChange(false)}
                            />
                        ) : (
                            <CloseCircleOutlined
                                className={styles.No}
                                disabled={isLoading}
                                onClick={() => !isLoading && onChange(true)}
                            />
                        )}
                    </div>
                ),
            }),
        )
    }

    const parsedActions = actions?.map((action, index) => {
        const data: Record<string, boolean> = {}

        action?.rules?.forEach((rule, index) => {
            const key = `rule${index + 1}`
            data[key] = rule
        })

        return { ...action, ...data, index, key: `action-${index}` }
    })

    return (
        <div className={styles.Container}>
            <div className={styles.TableWrapper}>
                <Table columns={COLUMNS} dataSource={parsedActions} />
            </div>
        </div>
    )
}
