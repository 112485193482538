import imgBox from '../../../_assets/box.jpg'
import imgLight from '../../../_assets/light.jpg'
import { getColorLabel } from '../../../components/ColorPicker/ColorPicker.utils'
import { ASSET_TYPE, BUTTON, EVENT, TCondition } from '../../../types'

export const getTrigger = (condition: TCondition) => {
    if (condition.assetType === ASSET_TYPE.LIGHT) return 'LIGHT'
    if (condition.button === BUTTON.BUTTON_1) return 'BUTTON_1'
    if (condition.button === BUTTON.BUTTON_2) return 'BUTTON_2'
    throw new Error('Unknown trigger')
}

const getCondition = (condition: TCondition) => {
    if (condition.event === EVENT.COLOUR) {
        if (condition.color === undefined) throw new Error('Color parse error')
        return `COLOR:${getColorLabel(condition.color).toUpperCase()}`
    }

    return `PRESS`
}

export function parseConditionsToConditionRows(conditions?: TCondition[]) {
    return (
        conditions?.map((condition, index) => {
            const assetType = condition.assetType === ASSET_TYPE.LIGHT ? 'KIS.LIGHT' : 'KIS.BOX'
            const trigger = getTrigger(condition)
            const cond = getCondition(condition)
            const id = `${condition.asset?.id}:${assetType}:${trigger}:${cond}`
            const data: Record<string, boolean | 'none'> = {}

            condition?.rules?.forEach((rule, index) => {
                const key = `rule${index + 1}`
                data[key] =
                    rule.expected === undefined || rule.expected === null ? 'none' : rule.expected
                data[key + 'current'] = rule.isMatch
            })

            return {
                index,
                image: assetType === 'KIS.BOX' ? imgBox : imgLight,
                key: `condition-${index}`,
                device: condition.asset?.name,
                condition: id,
                ...data,
            }
        }) ?? []
    )
}
