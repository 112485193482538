import { useEffect, useState } from 'react'

import { ASSET_TYPE, BUTTON, COLOR, EVENT, TAsset, TAssetDetails, TCondition } from '../types'
import { useApi } from './useApi'

export const useConditionBuilder = () => {
    const [selectedAsset, setSelectedAsset] = useState<TAsset>()
    const [selectedAssetType, setSelectedAssetType] = useState<ASSET_TYPE>()
    const [selectedButton, setSelectedButton] = useState<BUTTON>()
    const [selectedEvent, setSelectedEvent] = useState<EVENT>()
    const [selectedColor, setSelectedColor] = useState<COLOR>()
    const [assetDetails, setAssetDetails] = useState<TAssetDetails>()
    const { assets } = useApi()

    const condition: TCondition = {
        asset: selectedAsset,
        assetType: selectedAssetType,
        button: selectedButton,
        event: selectedEvent,
        color: selectedColor,
    }

    const getAssetDetails = async () => {
        if (selectedAsset && selectedAsset.urn) {
            const result = await assets.getDetails.fetch(selectedAsset.urn)
            setAssetDetails(result)
        }
    }

    useEffect(() => {
        getAssetDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAsset])

    useEffect(() => {
        if (assetDetails && assetDetails.hardware.type) {
            const newType =
                assetDetails.hardware.type === 'KIS.LIGHT' ? ASSET_TYPE.LIGHT : ASSET_TYPE.BOX
            setSelectedAssetType(newType)
            if (newType === ASSET_TYPE.LIGHT) setSelectedEvent(EVENT.COLOUR)
        }
    }, [assetDetails])

    const handleAssetChange = (asset?: TAsset) => {
        reset()
        setSelectedAsset(asset)
    }
    const handleButtonChange = (button?: BUTTON) => setSelectedButton(button)
    const handleEventChange = (event?: EVENT) => setSelectedEvent(event)
    const handleColorChange = (color?: COLOR) => setSelectedColor(color)

    const reset = () => {
        setSelectedAsset(undefined)
        setSelectedAssetType(undefined)
        setSelectedButton(undefined)
        setSelectedEvent(undefined)
        setSelectedColor(undefined)
    }

    return {
        handleAssetChange,
        handleButtonChange,
        handleEventChange,
        handleColorChange,
        reset,
        condition,
        isLoading: assets.getDetails.isLoading,
    }
}
