import { ReactElement } from 'react'

import { Table as AntdTable, Typography } from 'antd'

import styles from './Table.module.scss'

const { Title } = Typography

export type TRenderTableTitle = () => ReactElement

export type TColumn = {
    key: string
    dataIndex: string
    title: string | TRenderTableTitle
    width?: number | string
    render?: any
}

export type TTableProps = {
    title?: string
    columns: TColumn[]
    renderAdditional?: () => ReactElement
    dataSource?: Array<object>
}

export const Table = ({ title, columns, renderAdditional, dataSource }: TTableProps) => {
    return (
        <div>
            {title && (
                <Typography>
                    <Title level={3} className={styles.title}>
                        {title}
                    </Title>
                </Typography>
            )}
            {renderAdditional?.()}
            <AntdTable dataSource={dataSource} columns={columns} className={styles.table} />
        </div>
    )
}
