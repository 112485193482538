import { ReactElement } from 'react'

import { DeleteOutlined } from '@ant-design/icons'
import { Button } from 'antd'

import { CONTENT } from '../../../consts'
import { TRule } from '../../../types'

export type TValueColumn = {
    index: number
    key: string
    titleClassName?: string
    onRuleChange: (
        actionIndex: number,
        ruleIndex: number,
        value: TRule['expected'] | boolean,
    ) => void
    renderCellContent: (
        value: boolean,
        onChange: (newValue: TRule['expected']) => void,
        current: boolean,
    ) => ReactElement
    deleteRule?: (index: number) => void
}

export const RuleColumn = ({
    index,
    key,
    titleClassName,
    renderCellContent,
    onRuleChange,
    deleteRule,
}: TValueColumn) => {
    const dataIndex = `rule${index}`

    return {
        key: `${key}-${index}`,
        dataIndex,
        width: 80,
        title: () => (
            <div className={titleClassName}>
                <span>{CONTENT.CONDITIONS.TABLE.RULE_HEADER_PREFIX + index}</span>
                {deleteRule && (
                    <Button
                        shape="circle"
                        icon={<DeleteOutlined />}
                        size="small"
                        onClick={() => deleteRule(index - 1)}
                    />
                )}
            </div>
        ),
        render: (value: boolean, data: any) => {
            const onChange = (newValue: TRule['expected']) => {
                onRuleChange(Number(data['index']), index - 1, newValue)
            }

            return renderCellContent(value, onChange, data[dataIndex + 'current'])
        },
    }
}
