import classNames from 'classnames'

import styles from './FloatingButton.module.scss'

export type TFloatingButton = {
    text: string
    onClick: () => void
    disabled?: boolean
}

export const FloatingButton = ({ text, onClick, disabled }: TFloatingButton) => (
    <div className={classNames(styles.Container, { [styles.Blocked]: disabled })} onClick={onClick}>
        <div>{text}</div>
    </div>
)
