import styles from './Link.module.scss'

export type TLinkProps = {
    text: string
    href: string
}

export const Link = ({ text, href }: TLinkProps) => {
    return (
        <a className={styles.Container} href={href} target="_blank" rel="noreferrer">
            {text}
        </a>
    )
}
