import { useState } from 'react'

import { Button } from 'antd'

import { TextBox } from '../../../components'
import { CONTENT } from '../../../consts'

import styles from './AuthForm.module.scss'

export type TAuthFormProps = { onClick: (apiKey: string, apiClientId: string) => void }

export const AuthForm = ({ onClick }: TAuthFormProps) => {
    const [apiKey, setApiKey] = useState<string>()
    const [apiClientId, setApiClientId] = useState<string>()
    const hasData = apiKey && apiClientId && apiKey !== '' && apiClientId !== ''

    const handleOnClick = () => {
        if (hasData) {
            onClick(apiKey, apiClientId)
            setApiKey(undefined)
            setApiClientId(undefined)
        }
    }

    return (
        <form className={styles.Wrapper}>
            <h2>{CONTENT.AUTH.TITLE}</h2>
            <TextBox
                style={{ width: 310 }}
                label={CONTENT.AUTH.API_KEY}
                handleChange={setApiKey}
                value={apiKey}
            />
            <TextBox
                style={{ width: 310 }}
                label={CONTENT.AUTH.API_CLIENT_ID}
                handleChange={setApiClientId}
                value={apiClientId}
            />
            <Button
                className={styles.Button}
                type="primary"
                htmlType="submit"
                onClick={handleOnClick}
                disabled={!hasData}
            >
                {CONTENT.AUTH.LOGIN}
            </Button>
        </form>
    )
}
