import { useState } from 'react'

import { App } from 'antd'
import websocket from 'websocket'
import webstomp from 'webstomp-client'

import { CONTENT } from '../consts'
import { useApi } from './useApi'
import { useMessagesQueue } from './useMessageQueue'

const WebSocket = websocket.w3cwebsocket

export const useWebSocket = () => {
    const [subscriptionKey, setSubscriptionKey] = useState<string>()
    const { notification } = App.useApp()
    const { websockets } = useApi()
    const { addMessageToQueue } = useMessagesQueue()

    const subscribe = async () => {
        if (subscriptionKey) {
            await unsubscribe()
        }

        const response = await websockets.subscribe.fetch()

        if (response) {
            setSubscriptionKey(response.subscriptionId)
            const server = response.subscriptionUris[0].replace('///', '//')
            const socket = new WebSocket(server, undefined)
            const stomp = webstomp.over(socket, { debug: false, protocols: ['v12.stomp'] })
            stomp.connect({ host: server }, () => {
                console.log(`WebSocket --> CONNECTED: ${response.subscriptionId}`)
                stomp.subscribe(`/topic/${response.subscriptionId}`, (message) => {
                    addMessageToQueue(message.body)
                })
            })
        } else {
            notification.info({ message: CONTENT.WEBSOCKETS.UNABLE_TO_CREATE })
            setSubscriptionKey(undefined)
        }
    }

    const unsubscribe = async () => {
        if (!subscriptionKey) {
            console.log('WebSocket --> CANNOT BE DISCONNECTED / CONNECTION WAS CLOSED')
        } else {
            await websockets.unsubscribe.fetch(subscriptionKey)
            console.log('WebSocket --> DISCONNECTED')
        }
    }

    return { subscribe, unsubscribe, subscriptionKey }
}
