import { useState } from 'react'

import { useDefaultContext } from '../app'
import { TAction, TAppState, TAsset, TAssetDetails, TCondition } from '../types'
import { useCallApi } from './useCallApi'

const API_URL = process.env.REACT_APP_API_URL + '/api'

const getUrl = (uri: string) => `${API_URL}${uri}`

export const API_URLS = {
    ACTION: (index: number) => getUrl(`/state/actions/${index}`),
    ACTION_ADD: (ruleId: string, groupId: string) => getUrl(`/state/actions/${ruleId}/${groupId}`),
    ASSETS: () => getUrl(`/assets`),
    ASSET_DETAILS: (urn: string) => getUrl(`/assets/${urn}`),
    AUTH: (apiKey: string, apiClientKey: string) => getUrl(`/auth/login/${apiKey}/${apiClientKey}`),
    CHECK_LOGIN: () => getUrl(`/auth/login`),
    CONDITION: (index: number) => getUrl(`/state/conditions/${index}`),
    CONDITIONS: () => getUrl(`/state/conditions`),
    MESSAGE: (asset: number, key: string, value: string) =>
        getUrl(`/state/message/asset/${asset}/event/${key}/value/${value}`),
    RULES: () => getUrl(`/state/rules`),
    RULE: (index: number) => getUrl(`/state/rules/${index}`),
    RULE_ACTIONS_UPDATE: (conditionId: number, id: number, value: boolean | string) =>
        getUrl(`/state/rules/action/${conditionId}/id/${id}/${value}`),
    RULE_CONDITIONS_UPDATE: (conditionId: number, id: number, value: boolean | string) =>
        getUrl(`/state/rules/condition/${conditionId}/id/${id}/${value}`),
    SYNC: () => getUrl(`/state/sync`),
    WEBSOCKETS_SUBSCRIBE: () => getUrl(`/websockets/subscribe`),
    WEBSOCKETS_UNSUBSCRIBE: (key: string) => getUrl(`/websockets/unsubscribe/${key}`),
}

export const useApi = () => {
    const { updateState } = useDefaultContext()

    // Auth
    const [isGetLoginLoading, setIsGetLoginLoading] = useState<boolean>()
    const [isGetTokenLoading, setIsGetTokenLoading] = useState<boolean>()
    // Assets
    const [isGetAssetsLoading, setIsGetAssetsLoading] = useState<boolean>()
    const [isGetAssetsDetailsLoading, setIsGetAssetsDetailsLoading] = useState<boolean>()
    // Rules
    const [isAddRulesLoading, setIsAddRulesLoading] = useState<boolean>()
    const [isUpdateConditionsRuleLoading, setIsUpdateConditionsRuleLoading] = useState<boolean>()
    const [isUpdateActionsRuleLoading, setIsUpdateActionsRuleLoading] = useState<boolean>()
    const [isDeleteRuleLoading, setIsDeleteRuleLoading] = useState<boolean>()
    // Conditions
    const [isAddConditionLoading, setIsAddConditionLoading] = useState<boolean>()
    const [isDeleteConditionLoading, setIsDeleteConditionLoading] = useState<boolean>()
    // Actions
    const [isAddActionLoading, setIsAddActionLoading] = useState<boolean>()
    const [isDeleteActionLoading, setIsDeleteActionLoading] = useState<boolean>()
    // State
    const [isStateSyncLoading, setIsStateSyncLoading] = useState<boolean>()
    const [isMessageLoading, setIsMessageLoading] = useState<boolean>()
    // Websockets
    const [isSubscribeLoading, setIsSubscribeLoading] = useState<boolean>()
    const [isUnsubscribeLoading, setIsUnsubscribeLoading] = useState<boolean>()

    const { callApi } = useCallApi()

    return {
        actions: {
            add: {
                isLoading: isAddActionLoading,
                fetch: async (ruleId: string, groupId: string) => {
                    return await callApi<TAppState>(
                        {
                            url: API_URLS.ACTION_ADD(ruleId, groupId),
                            method: 'POST',
                        },
                        setIsAddActionLoading,
                    )
                },
            },
            delete: {
                isLoading: isDeleteActionLoading,
                fetch: async (index: number) => {
                    const result = await callApi<TAppState>(
                        {
                            url: API_URLS.ACTION(index),
                            method: 'DELETE',
                        },
                        setIsDeleteActionLoading,
                    )
                    return updateState(result, 'actions.delete')
                },
            },
        },
        assets: {
            get: {
                isLoading: isGetAssetsLoading,
                fetch: async () => {
                    return await callApi<TAsset[]>(
                        { url: API_URLS.ASSETS() },
                        setIsGetAssetsLoading,
                    )
                },
            },
            getDetails: {
                isLoading: isGetAssetsDetailsLoading,
                fetch: async (urn: string) => {
                    return await callApi<TAssetDetails>(
                        { url: API_URLS.ASSET_DETAILS(urn) },
                        setIsGetAssetsDetailsLoading,
                    )
                },
            },
        },
        auth: {
            login: {
                isLoading: isGetLoginLoading,
                fetch: async () => {
                    return await callApi<string>(
                        { url: API_URLS.CHECK_LOGIN() },
                        setIsGetLoginLoading,
                    )
                },
            },
            getToken: {
                isLoading: isGetTokenLoading,
                fetch: async (apiKey: string, apiClientId: string) => {
                    return await callApi<string>(
                        { url: API_URLS.AUTH(apiKey, apiClientId) },
                        setIsGetTokenLoading,
                    )
                },
            },
        },
        conditions: {
            add: {
                isLoading: isAddConditionLoading,
                fetch: async (data: TCondition) => {
                    const result = await callApi<TAppState>(
                        {
                            url: API_URLS.CONDITIONS(),
                            data,
                            method: 'POST',
                        },
                        setIsAddConditionLoading,
                    )
                    return updateState(result, 'conditions.add')
                },
            },
            delete: {
                isLoading: isDeleteConditionLoading,
                fetch: async (index: number) => {
                    const result = await callApi<TAppState>(
                        {
                            url: API_URLS.CONDITION(index),
                            method: 'DELETE',
                        },
                        setIsDeleteConditionLoading,
                    )
                    return updateState(result, 'conditions.delete')
                },
            },
        },
        rules: {
            add: {
                isLoading: isAddRulesLoading,
                fetch: async () => {
                    const result = await callApi<TAppState>(
                        {
                            url: API_URLS.RULES(),
                            method: 'POST',
                        },
                        setIsAddRulesLoading,
                    )
                    return updateState(result, 'rules.add')
                },
            },
            update: {
                actions: {
                    isLoading: isUpdateActionsRuleLoading,
                    fetch: async (conditionId: number, id: number, value: boolean | string) => {
                        const result = await callApi<TAppState>(
                            {
                                url: API_URLS.RULE_ACTIONS_UPDATE(conditionId, id, value),
                                method: 'PUT',
                            },
                            setIsUpdateActionsRuleLoading,
                        )
                        return updateState(result, 'rules.update.actions')
                    },
                },
                conditions: {
                    isLoading: isUpdateConditionsRuleLoading,
                    fetch: async (conditionId: number, id: number, value: boolean | string) => {
                        const result = await callApi<TAppState>(
                            {
                                url: API_URLS.RULE_CONDITIONS_UPDATE(conditionId, id, value),
                                method: 'PUT',
                            },
                            setIsUpdateConditionsRuleLoading,
                        )
                        return updateState(result, 'rules.update.conditions')
                    },
                },
            },
            delete: {
                isLoading: isDeleteRuleLoading,
                fetch: async (index: number) => {
                    const result = await callApi<TAppState>(
                        {
                            url: API_URLS.RULE(index),
                            method: 'DELETE',
                        },
                        setIsDeleteRuleLoading,
                    )
                    return updateState(result, 'rules.delete')
                },
            },
        },
        state: {
            sync: {
                isLoading: isStateSyncLoading,
                fetch: async () => {
                    const result = await callApi<TAppState>(
                        {
                            url: API_URLS.SYNC(),
                        },
                        setIsStateSyncLoading,
                    )
                    return updateState(result, 'state.sync')
                },
            },
            message: {
                isLoading: isMessageLoading,
                fetch: async (asset: number, key: string, value: string) => {
                    const result = await callApi<{ state: TAppState; bingoActions: TAction[] }>(
                        { url: API_URLS.MESSAGE(asset, key, value), method: 'PATCH' },
                        setIsMessageLoading,
                    )

                    updateState(result?.state, 'state.message')

                    return result
                },
            },
        },
        websockets: {
            subscribe: {
                isLoading: isSubscribeLoading,
                fetch: async () => {
                    return await callApi<{
                        subscriptionId: string
                        subscriptionUris: string[]
                    }>(
                        {
                            url: API_URLS.WEBSOCKETS_SUBSCRIBE(),
                        },
                        setIsSubscribeLoading,
                    )
                },
            },
            unsubscribe: {
                isLoading: isUnsubscribeLoading,
                fetch: async (subscriptionKey: string) => {
                    return await callApi(
                        {
                            method: 'DELETE',
                            url: API_URLS.WEBSOCKETS_UNSUBSCRIBE(subscriptionKey),
                        },
                        setIsUnsubscribeLoading,
                    )
                },
            },
        },
    }
}
