import { PropsWithChildren } from 'react'

import { Collapse } from 'antd'

const { Panel } = Collapse

export type TBoxProps = PropsWithChildren<{ title: string; fieldKey: string; isOpen?: boolean }>

export const Box = ({ children, title, fieldKey, isOpen }: TBoxProps) => {
    return (
        <Collapse defaultActiveKey={isOpen ? fieldKey : undefined}>
            <Panel header={title} key={fieldKey}>
                {children}
            </Panel>
        </Collapse>
    )
}
