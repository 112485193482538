import { App } from 'antd'

import { useDefaultContext } from '../../app'
import { Box, FloatingButton } from '../../components'
import { CONTENT } from '../../consts'
import { useApi } from '../../hooks/useApi'
import { useConditionBuilder } from '../../hooks/useConditionBuilder'
import { TCondition, TRule } from '../../types'
import { ActionsForm } from './ActionsForm'
import { ActionsTable } from './ActionsTable'
import { ConditionsForm } from './ConditionsForm'
import { ConditionsTable } from './ConditionsTable'

import styles from './AppView.module.scss'

export type TAppViewProps = {
    handleSubscribe: () => void
}

export const AppView = ({ handleSubscribe }: TAppViewProps) => {
    const conditionBuilder = useConditionBuilder()
    const { setAssets, assets, appState, rulesCount, updateState } = useDefaultContext()
    const { notification } = App.useApp()
    const api = useApi()

    const fetchAssets = async () => {
        const response = await api.assets.get.fetch()
        setAssets(response ?? [])
    }

    const handleAddCondition = async (newCondition: TCondition) => {
        const existingCondition = appState?.conditions.find(
            (condition) =>
                condition.asset?.id === newCondition.asset?.id &&
                condition.assetType === newCondition.assetType &&
                condition.button === newCondition.button &&
                condition.event === newCondition.event &&
                condition.color === newCondition.color,
        )

        if (existingCondition) notification.error({ message: CONTENT.CONDITIONS.FORM.ERROR_EXISTS })
        else {
            conditionBuilder.reset()
            await api.conditions.add.fetch(newCondition)
            handleSubscribe()
        }
    }

    const handleDeleteCondition = async (index: number) => {
        await api.conditions.delete.fetch(index)
        handleSubscribe()
    }

    const handleDeleteRule = async (index: number) => {
        await api.rules.delete.fetch(index)
    }

    const handleConditionRuleChange = async (
        conditionIndex: number,
        ruleIndex: number,
        value: TRule['expected'],
    ) => {
        await api.rules.update.conditions.fetch(
            conditionIndex,
            ruleIndex,
            value === undefined ? 'none' : value ? 'true' : 'false',
        )
    }

    const handleActionRuleChange = async (
        actionIndex: number,
        ruleIndex: number,
        value: TRule['expected'],
    ) => {
        await api.rules.update.actions.fetch(actionIndex, ruleIndex, value ? 'true' : 'false')
    }

    const handleAddAction = async (ruleId: string, groupId: string) => {
        const result = await api.actions.add.fetch(ruleId, groupId)
        if (result) updateState(result, 'action.add')
        else notification.error({ message: CONTENT.ACTIONS.FORM.ERROR })
    }

    const handleRuleDelete = async (index: number) => {
        await api.actions.delete.fetch(index)
    }

    return (
        <div className={styles.Container}>
            <FloatingButton
                text={CONTENT.ADD_RULE}
                onClick={api.rules.add.fetch}
                disabled={!appState?.conditions || appState.conditions.length === 0}
            />
            <div className={styles.Conditions}>
                <h2>{CONTENT.CONDITIONS.TITLE}</h2>
                <Box title={CONTENT.CONDITIONS.FORM.TITLE} fieldKey="condition-form" isOpen={true}>
                    <ConditionsForm
                        areAssetsLoading={api.assets.get.isLoading}
                        condition={conditionBuilder.condition}
                        handleAssetChange={conditionBuilder.handleAssetChange}
                        handleButtonChange={conditionBuilder.handleButtonChange}
                        handleEventChange={conditionBuilder.handleEventChange}
                        handleColorChange={conditionBuilder.handleColorChange}
                        handleAddCondition={handleAddCondition}
                        loadAssets={fetchAssets}
                        assets={assets}
                    />
                </Box>
                <ConditionsTable
                    conditions={appState?.conditions}
                    rulesCount={rulesCount}
                    deleteCondition={handleDeleteCondition}
                    deleteRule={handleDeleteRule}
                    onRuleChange={handleConditionRuleChange}
                />
            </div>
            <div className={styles.Actions}>
                <h2>{CONTENT.ACTIONS.TITLE}</h2>
                <Box title={CONTENT.ACTIONS.FORM.TITLE} fieldKey="condition-form" isOpen={true}>
                    <ActionsForm
                        isLoading={api.actions.add.isLoading}
                        handleAddAction={handleAddAction}
                    />
                </Box>
                <ActionsTable
                    actions={appState?.actions}
                    rulesCount={rulesCount}
                    deleteAction={handleRuleDelete}
                    onRuleChange={handleActionRuleChange}
                />
            </div>
        </div>
    )
}
