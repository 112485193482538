import { ReloadOutlined } from '@ant-design/icons'

import { Select, TSelectOptions } from '../../../../components'
import { CONTENT } from '../../../../consts'

export type TSelectAssetFieldProps = {
    isLoading?: boolean
    size: number
    options: TSelectOptions<number>
    onButtonClick: () => void
    handleAssetChange: (assetId: number) => void
    currentAssetId?: number
}

export const SelectAssetField = ({
    size,
    options,
    isLoading,
    onButtonClick,
    handleAssetChange,
    currentAssetId,
}: TSelectAssetFieldProps) => {
    return (
        <Select
            label={CONTENT.CONDITIONS.FORM.SELECT_ASSET_LABEL}
            style={{ width: size }}
            isLoading={isLoading}
            options={options ?? []}
            button={<ReloadOutlined />}
            onButtonClick={onButtonClick}
            handleChange={handleAssetChange}
            value={currentAssetId}
            showSearch
        />
    )
}
