import { useEffect } from 'react'

import { useAuth } from '../hooks/useAuth'
import { useStateQueries } from '../hooks/useStateQueries'
import { useWebSocket } from '../hooks/useWebSocket'
import { AppView } from '../views/AppView'
import { Layout } from '../views/Layout'
import { LoginView } from '../views/LoginView'
import { useDefaultContext } from './Context'

export const App = () => {
    const { isLogged } = useDefaultContext()
    const { checkToken } = useAuth()
    const stateQueries = useStateQueries()
    const { subscribe } = useWebSocket()

    useEffect(() => {
        checkToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (isLogged) stateQueries.sync(handleSubscribe)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLogged])

    const handleSubscribe = () => subscribe()

    return (
        <Layout>{isLogged ? <AppView handleSubscribe={handleSubscribe} /> : <LoginView />}</Layout>
    )
}
