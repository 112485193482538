import dayjs from 'dayjs'

import { Link } from '../../../components'

import styles from './Footer.module.scss'

export const Footer = () => {
    const date = dayjs()
    const years = date.year() === 2023 ? '2023' : `2022-${date.year()}`
    const message = `© Copyright ${years}, `

    return (
        <div className={styles.Footer}>
            <div className={styles.Version}>v. {process.env.REACT_APP_VERSION ?? '???'}</div>
            <div className={styles.FooterContent}>
                {message}
                <Link text="Lervana Katarzyna Dadek" href="https://www.linkedin.com/in/kdadek/" />
                <span>&</span>
                <Link text="Sławomir Dadek" href="https://www.linkedin.com/in/sdadek/" />
            </div>
        </div>
    )
}
