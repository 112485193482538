import { DeleteOutlined } from '@ant-design/icons'
import { Button } from 'antd'

export type TValueColumn = {
    title: string
    key: string
    dataIndex: string
    width?: number | string
    className?: string
    deleteAction: (index: number) => void
}

export const WithDeleteColumn = ({
    title,
    key,
    dataIndex,
    width,
    className,
    deleteAction,
}: TValueColumn) => ({
    title,
    key,
    dataIndex,
    width,
    render: (value: string, data: { index: number }) => {
        return (
            <div className={className}>
                <Button
                    shape="circle"
                    icon={<DeleteOutlined />}
                    size="small"
                    onClick={() => deleteAction(data.index)}
                />
                <span>{value}</span>
            </div>
        )
    },
})
