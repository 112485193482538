import { CSSProperties, ReactElement } from 'react'

import { Select as AntdSelect, Button, Spin } from 'antd'
import classNames from 'classnames'

import styles from './Select.module.scss'

export type TSelectOptions<T> = { value: T; label: string }[]
export type TSelectHandleChange<T> = (data: T) => void

export type TSelectProps<T> = {
    options?: TSelectOptions<T>
    label?: string
    isLoading?: boolean
    className?: string
    style?: CSSProperties
    button?: ReactElement
    onButtonClick?: () => void
    handleChange?: TSelectHandleChange<T>
    defaultValue?: T
    value?: T
    showSearch?: boolean
    disabled?: boolean
}

export const Select = <T,>({
    label,
    isLoading,
    options = [],
    className,
    style,
    button,
    onButtonClick,
    defaultValue,
    value,
    showSearch,
    disabled,
    handleChange,
}: TSelectProps<T>) => {
    return (
        <div className={classNames(styles.Container, className)}>
            {label && <label>{label}</label>}
            {isLoading ? (
                <div className={styles.SpinnerWrapper}>
                    <Spin />
                </div>
            ) : (
                <div className={styles.SelectWithButton}>
                    <AntdSelect
                        disabled={disabled}
                        defaultValue={defaultValue}
                        style={style}
                        onChange={handleChange}
                        options={options}
                        value={value}
                        showSearch={showSearch}
                        filterOption={(inputValue, option) => {
                            return (
                                option?.label?.toLowerCase().includes(inputValue.toLowerCase()) ??
                                false
                            )
                        }}
                    />
                    {button && (
                        <Button shape="circle" icon={button} size="small" onClick={onButtonClick} />
                    )}
                </div>
            )}
        </div>
    )
}
