import { App } from 'antd'

import { useDefaultContext } from '../app'
import { CONTENT, COOKIE_NAME } from '../consts'
import { useApi } from './useApi'
import { useCookies } from './useCookies'

export const useAuth = () => {
    const { setIsLogged, setIsAppLoading } = useDefaultContext()
    const { removeCookie, setCookie } = useCookies()
    const { notification } = App.useApp()
    const { auth } = useApi()

    const checkToken = async () => {
        const result = await auth.login.fetch()
        const isLogged = result !== undefined
        setIsLogged(isLogged)
        // if (!isLogged) removeCookie(COOKIE_NAME)
        setIsAppLoading(false)
    }

    const logout = () => {
        removeCookie(COOKIE_NAME)
        setIsLogged(false)
        setIsAppLoading(false)
    }

    const login = async (apiKey: string, apiClientId: string) => {
        setIsAppLoading(true)
        if (apiKey && apiClientId) {
            const token = await auth.getToken.fetch(apiKey, apiClientId)

            if (token) {
                setCookie(COOKIE_NAME, token)
                setIsLogged(true)
                notification.success({ message: CONTENT.AUTH.AUTHORIZED })
            } else {
                notification.error({ message: CONTENT.AUTH.UNAUTHORIZED })
            }

            setIsAppLoading(false)
        } else {
            logout()
        }
    }

    return {
        checkToken,
        login,
        logout,
    }
}
