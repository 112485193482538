import { RightCircleOutlined } from '@ant-design/icons'
import { Typography } from 'antd'

import logo from '../../../_assets/black-cat-vertical-v2.png'
import { CONTENT } from '../../../consts'

import styles from './PageTitle.module.scss'

const { Title, Paragraph } = Typography

export const PageTitle = ({ hideText, onLogout }: { hideText?: boolean; onLogout: () => void }) => {
    return (
        <div className={styles.Container}>
            <a href="https://lervana.dev" target="_blank" rel="noreferrer">
                <img src={logo} alt={'lervana.dev'} />
            </a>
            <Typography className={styles.Header}>
                <Title>KIS app tool</Title>
                {!hideText && <Paragraph>{CONTENT.HEADER.DESCRIPTION}</Paragraph>}
            </Typography>
            <div className={styles.Logout} onClick={onLogout}>
                <div className={styles.LogoutText}>{CONTENT.LOGOUT}</div>
                <RightCircleOutlined className={styles.LogoutImage} />
            </div>
        </div>
    )
}
