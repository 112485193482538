import React from 'react'
import ReactDOM from 'react-dom/client'

import { App as AntdApp, ConfigProvider } from 'antd'

import { App } from './app/App'
import { ContextWrapper } from './app/Context'

import './index.scss'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    // <React.StrictMode>
    <AntdApp>
        <ConfigProvider theme={{ token: { colorPrimary: '#36b0b0' } }}>
            <ContextWrapper>
                <App />
            </ContextWrapper>
        </ConfigProvider>
    </AntdApp>,
    // </React.StrictMode>,
)
