import { useCookies as reactUseCookies } from 'react-cookie'

export function useCookies(dependencies?: string[]) {
    const [cookies, setCookie, removeCookie] = reactUseCookies(dependencies)

    return {
        cookies,
        setCookie,
        removeCookie,
    }
}
