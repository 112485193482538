import { PropsWithChildren } from 'react'

import { Divider, Spin } from 'antd'

import { useDefaultContext } from '../../app'
import { useAuth } from '../../hooks/useAuth'
import { Footer } from './Footer'
import { PageTitle } from './PageTitle'

import styles from './Layout.module.scss'

export const Layout = ({ children }: PropsWithChildren) => {
    const { isLogged, isAppLoading, isSyncDone } = useDefaultContext()
    const { logout } = useAuth()

    const isLoaded = !isAppLoading && (!isLogged || (isSyncDone && isLogged))

    return (
        <div className={styles.Layout}>
            <div className={styles.Container}>
                <PageTitle onLogout={logout} hideText={!isLogged} />
                <Divider />
                <div className={styles.Content}>{isLoaded ? children : <Spin size="large" />}</div>
                <Divider />
                <Footer />
            </div>
        </div>
    )
}
