export type TValueColumn = {
    title: string
    key: string
    dataIndex: string
    width?: number | string
    className?: string
}

export const ValueColumn = ({ title, key, dataIndex, width, className }: TValueColumn) => ({
    title,
    key,
    dataIndex,
    width,
    render: (value: string) => {
        return (
            <div className={className}>
                <span>{value}</span>
            </div>
        )
    },
})
