import { TMessage, useDefaultContext } from '../app'
import { ASSET_TYPE, BUTTON, EVENT } from '../types'
import { useApi } from './useApi'

export const useStateQueries = () => {
    const {
        isSyncDone,
        setIsSyncDone,
        setAssets,
        appState,
        assets: assetsArray,
        updateState,
    } = useDefaultContext()
    const { state, assets } = useApi()

    const sync = async (handleSubscribe: () => void) => {
        await state.sync.fetch()
        const response = await assets.get.fetch()
        setAssets(response ?? [])
        setIsSyncDone(true)
        await handleSubscribe()
    }

    const trigger = async (message: TMessage) => {
        const asset = assetsArray?.find((item) => item.id.toString() === message.nodeId.toString())
        const button1Pressed = message.info.key === 'button1Pressed'
        const button2Pressed = message.info.key === 'button2Pressed'

        if (asset && (button1Pressed || button1Pressed)) {
            const newConditions = appState?.conditions.map((condition) => {
                const isMatch =
                    condition.event === EVENT.PRESS &&
                    condition.asset?.id.toString() === asset.id.toString() &&
                    condition.assetType === ASSET_TYPE.BOX &&
                    ((button1Pressed && condition.button === BUTTON.BUTTON_1) ||
                        (button2Pressed && condition.button === BUTTON.BUTTON_2))

                if (isMatch) {
                    return {
                        ...condition,
                        rules: condition.rules?.map((rule) => ({
                            ...rule,
                            current: true,
                            isMatch:
                                rule.expected === undefined ||
                                rule.expected === null ||
                                rule.expected,
                        })),
                    }
                }

                return condition
            })

            const tmpState = { ...appState, conditions: newConditions ?? [] }
            updateState(tmpState, 'tmp.button')
        }

        await state.message.fetch(message.nodeId, message.info.key, message.info.value)
    }

    return {
        isSyncDone,
        sync,
        trigger,
    }
}
