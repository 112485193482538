import { Select } from '../../../../components'
import { BUTTON, BUTTONS_OPTIONS } from '../../../../types'

export type TSelectAssetFieldProps = {
    text: string
    size: number
    handleChange: (button?: BUTTON) => void
    value?: BUTTON
}

export const SelectButton = ({ text, size, handleChange, value }: TSelectAssetFieldProps) => {
    return (
        <Select
            label={text}
            style={{ width: size }}
            options={BUTTONS_OPTIONS}
            handleChange={handleChange}
            value={value}
        />
    )
}
