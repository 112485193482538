import { CSSProperties } from 'react'

import { Input, Spin } from 'antd'
import classNames from 'classnames'

import styles from './TextBox.module.scss'

export type TTextBox = {
    label?: string
    isLoading?: boolean
    className?: string
    style?: CSSProperties
    handleChange?: (value: string) => void
    disabled?: boolean
    value?: string
}

export const TextBox = ({
    label,
    isLoading,
    className,
    style,
    handleChange,
    disabled,
    value,
}: TTextBox) => {
    return (
        <div className={classNames(styles.Container, className)}>
            {label && <label>{label}</label>}
            {isLoading ? (
                <div className={styles.SpinnerWrapper}>
                    <Spin />
                </div>
            ) : (
                <div className={styles.SelectWithButton}>
                    <Input
                        style={style}
                        onChange={(event) => handleChange?.(event.target.value)}
                        disabled={disabled}
                        value={value}
                    />
                </div>
            )}
        </div>
    )
}
