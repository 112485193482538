import { Select } from '../../../../components'
import { EVENT, EVENTS_OPTIONS_BUTTON, EVENTS_OPTIONS_LIGHT } from '../../../../types'

export type TSelectEventProps = {
    text: string
    size: number
    isLight: boolean
    handleChange: (event: EVENT) => void
    value?: EVENT
}

export const SelectEvent = ({ text, size, isLight, handleChange, value }: TSelectEventProps) => {
    return (
        <Select
            label={text}
            style={{ width: size }}
            options={isLight ? EVENTS_OPTIONS_LIGHT : EVENTS_OPTIONS_BUTTON}
            defaultValue={isLight ? EVENTS_OPTIONS_LIGHT[0].value : undefined}
            handleChange={handleChange}
            value={value}
            disabled={isLight}
        />
    )
}
