import { useEffect, useState } from 'react'

import { TMessage } from '../app'
import { useStateQueries } from './useStateQueries'

const handledEvents = [
    'button1Pressed',
    'button2Pressed',
    'button1ColorKpiDuration',
    'button2ColorKpiDuration',
    'led1ColorKpiDuration',
]

export const useMessagesQueue = () => {
    const [messagesQueue, setMessagesQueue] = useState<TMessage[]>([])
    const [isMessageQueueProcessing, setIsMessageQueueProcessing] = useState<boolean>()
    const { trigger } = useStateQueries()

    useEffect(() => {
        processMessageQueue()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messagesQueue])

    const addMessageToQueue = (message: string) => {
        const parsedMessage = JSON.parse(message) as TMessage
        if (handledEvents.indexOf(parsedMessage.info.key) !== -1) {
            setMessagesQueue((prevMessages) => [...prevMessages, parsedMessage])
        }
    }

    async function processMessageQueue() {
        if (!isMessageQueueProcessing && messagesQueue.length > 0) {
            console.log('MESSAGE: START')
            setIsMessageQueueProcessing(true)

            const message = messagesQueue[0]
            await trigger(message)

            setMessagesQueue(messagesQueue.slice(1))
            setIsMessageQueueProcessing(false)
            console.log('MESSAGE: STOP')
        }
    }

    return { addMessageToQueue }
}
