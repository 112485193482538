import { TAppState } from './types'

export const COOKIE_NAME = 'KIS.APP.AUTH'

export const CONTENT = {
    AUTH: {
        TITLE: 'Logowanie',
        API_KEY: 'ApiKey',
        API_CLIENT_ID: 'ApiClientId',
        LOGIN: 'Zaloguj',
        UNAUTHORIZED: 'Niepoprawne dane logowania',
        AUTHORIZED: 'Zalogowano poprawnie',
    },
    HEADER: {
        TITLE: 'KIS App Tool',
        DESCRIPTION:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin sollicitudin,\n' +
            'dolor vitae sodales hendrerit, dolor sem ultricies leo, sit amet dictum ligula\n' +
            'odio non mi. Nam vel ante eleifend, vehicula sem at, interdum nulla. Integer a\n' +
            'diam non urna suscipit commodo a quis urna. Donec sit amet varius lorem. Proin\n' +
            'molestie imperdiet ex, non mollis felis vehicula at. Aliquam rhoncus porta justo\n' +
            'imperdiet pulvinar. Ut sagittis lectus vel tempor cursus.',
    },
    ADD_RULE: 'Dodaj regułę',
    CONDITIONS: {
        TITLE: 'Warunki',
        FORM: {
            TITLE: 'Dodawanie warunku',
            SELECT_ASSET_LABEL: 'Urządzenie',
            SELECT_TYPE_LOADER: 'Sprawdzanie typu',
            SELECT_BUTTON: 'Przycisk',
            SELECT_EVENT: 'Zdarzenie',
            SELECT_COLOR: 'Kolor',
            ADD: 'Dodaj warunek',
            ERROR_EXISTS: 'Podany warunek został już dodany',
        },
        TABLE: {
            TITLE: 'Warunki',
            DEVICE_HEADER: 'Urządzenie',
            CONDITION_HEADER: 'Warunek',
            RULE_HEADER_PREFIX: 'R',
        },
    },
    ACTIONS: {
        TITLE: 'Akcje',
        FORM: {
            TITLE: 'Dodawanie akcji',
            RULE_ID: 'ID reguły',
            ASSET_GROUP_ID: 'ID grupy',
            ADD: 'Dodaj akcję',
            ERROR: 'Nie można dodać akcji dla podanych danych.',
        },
        TABLE: {
            ID: 'Id',
            NAME: 'Nazwa',
            ASSET_GROUP_ID: 'AssetGroupId',
        },
    },
    BUTTONS: {
        BUTTON_1: 'Przycisk 1',
        BUTTON_2: 'Przycisk 2',
    },
    EVENTS: {
        COLOUR: 'Kolor',
        PRESS: 'Wciśnięcie',
    },
    COLORS: {
        WHITE: 'white',
        BLUE: 'blue',
        GREEN: 'green',
        RED: 'red',
        CYAN: 'cyjan',
        MAGENTA: 'magenta',
        YELLOW: 'yellow',
        BLACK: 'black',
    },
    WEBSOCKETS: {
        UNABLE_TO_CREATE: 'Nie można stworzyć WebSocketu',
    },
    LOGOUT: 'Wyloguj',
}

export const INIT_STATE: TAppState = {
    conditions: [],
    actions: [],
}
