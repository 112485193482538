import { CONTENT } from '../../consts'
import { COLOR } from '../../types'

export const getColor = (color: COLOR) => {
    switch (color) {
        case COLOR.WHITE:
            return '#FFFFFF'
        case COLOR.BLUE:
            return '#0000FF'
        case COLOR.GREEN:
            return '#00FF00'
        case COLOR.RED:
            return '#FF0000'
        case COLOR.CYAN:
            return '#00FFFF'
        case COLOR.MAGENTA:
            return '#FF00FF'
        case COLOR.YELLOW:
            return '#FFFF00'
        default:
            return '#000000'
    }
}

export const getColorLabel = (color: COLOR) => {
    switch (color) {
        case COLOR.WHITE:
            return CONTENT.COLORS.WHITE
        case COLOR.BLUE:
            return CONTENT.COLORS.BLUE
        case COLOR.GREEN:
            return CONTENT.COLORS.GREEN
        case COLOR.RED:
            return CONTENT.COLORS.RED
        case COLOR.CYAN:
            return CONTENT.COLORS.CYAN
        case COLOR.MAGENTA:
            return CONTENT.COLORS.MAGENTA
        case COLOR.YELLOW:
            return CONTENT.COLORS.YELLOW
        default:
            return CONTENT.COLORS.BLACK
    }
}
