import { Select } from '../../../../components'

export type TSelectAssetFieldProps = {
    text: string
    size: number
}

export const SelectLoader = ({ text, size }: TSelectAssetFieldProps) => {
    return <Select label={text} style={{ width: size }} isLoading={true} />
}
