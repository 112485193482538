import { Button, Spin } from 'antd'

import { ColorPicker, TSelectOptions } from '../../../components'
import { CONTENT } from '../../../consts'
import { ASSET_TYPE, BUTTON, COLOR, EVENT, TAsset, TCondition } from '../../../types'
import { SelectAssetField } from './fields/SelectAssetField'
import { SelectButton } from './fields/SelectButton'
import { SelectEvent } from './fields/SelectEvent'
import { SelectLoader } from './fields/SelectLoader'

import styles from './ConditionsForm.module.scss'

export type TConditionsFormProps = {
    areAssetsLoading?: boolean
    condition: TCondition
    handleAddCondition: (condition: TCondition) => void
    handleAssetChange: (asset: TAsset) => void
    handleButtonChange: (value?: BUTTON | undefined) => void
    handleEventChange: (value?: EVENT | undefined) => void
    handleColorChange: (value?: COLOR | undefined) => void
    loadAssets: () => void
    assets?: TAsset[]
}

const FIELD_SIZE = 240

export const ConditionsForm = ({
    condition,
    handleAddCondition,
    handleAssetChange,
    handleButtonChange,
    handleEventChange,
    handleColorChange,
    loadAssets,
    areAssetsLoading,
    assets,
}: TConditionsFormProps) => {
    const hasNoType = condition.asset !== undefined && condition.assetType === undefined
    const isLight = condition.assetType === ASSET_TYPE.LIGHT
    const isBox = condition.assetType === ASSET_TYPE.BOX
    const isColorEvent = condition.event === EVENT.COLOUR

    const assetsOptions: TSelectOptions<number> =
        assets?.map((asset) => {
            return { value: asset.id, label: asset.name }
        }) ?? []

    const onAssetChange = (value: number) => {
        const asset = assets?.find((asset) => asset.id === value)
        if (asset) return handleAssetChange(asset)
    }

    return (
        <div className={styles.Container}>
            {areAssetsLoading ? (
                <div className={styles.SpinnerWrapper}>
                    <Spin />
                </div>
            ) : (
                <div className={styles.Content}>
                    <SelectAssetField
                        size={FIELD_SIZE}
                        currentAssetId={condition.asset?.id}
                        isLoading={areAssetsLoading}
                        options={assetsOptions}
                        handleAssetChange={onAssetChange}
                        onButtonClick={loadAssets}
                    />
                    {hasNoType && (
                        <SelectLoader
                            size={FIELD_SIZE}
                            text={CONTENT.CONDITIONS.FORM.SELECT_TYPE_LOADER}
                        />
                    )}
                    {isBox && (
                        <SelectButton
                            size={FIELD_SIZE}
                            text={CONTENT.CONDITIONS.FORM.SELECT_BUTTON}
                            handleChange={handleButtonChange}
                            value={condition.button}
                        />
                    )}
                    {(isLight || (isBox && condition.button)) && (
                        <SelectEvent
                            text={CONTENT.CONDITIONS.FORM.SELECT_EVENT}
                            size={FIELD_SIZE}
                            isLight={condition.assetType === ASSET_TYPE.LIGHT}
                            handleChange={handleEventChange}
                            value={condition.event}
                        />
                    )}
                    {condition.event && isColorEvent && (
                        <ColorPicker
                            label={CONTENT.CONDITIONS.FORM.SELECT_COLOR}
                            onChange={handleColorChange}
                            value={condition.color}
                        />
                    )}
                    {(isColorEvent ? condition.color !== undefined : condition.event) && (
                        <Button
                            className={styles.Button}
                            type="primary"
                            onClick={() => {
                                handleAddCondition(condition)
                            }}
                        >
                            {CONTENT.CONDITIONS.FORM.ADD}
                        </Button>
                    )}
                </div>
            )}
        </div>
    )
}
