// ENUMS
import { CONTENT } from './consts'

export enum ASSET_TYPE {
    BOX,
    LIGHT,
}

export enum BUTTON {
    BUTTON_1 = 1,
    BUTTON_2 = 2,
}

export enum EVENT {
    COLOUR = 1,
    PRESS = 2,
}

export enum COLOR {
    BLUE,
    CYAN,
    BLACK,
    GREEN,
    MAGENTA,
    RED,
    WHITE,
    YELLOW,
}

// CONSTS
export const COLORS = [
    COLOR.WHITE,
    COLOR.BLUE,
    COLOR.GREEN,
    COLOR.RED,
    COLOR.CYAN,
    COLOR.MAGENTA,
    COLOR.YELLOW,
    COLOR.BLACK,
]

export const BUTTONS_OPTIONS = [
    {
        value: BUTTON.BUTTON_1,
        label: CONTENT.BUTTONS.BUTTON_1,
    },
    {
        value: BUTTON.BUTTON_2,
        label: CONTENT.BUTTONS.BUTTON_2,
    },
]

export const EVENTS_OPTIONS_LIGHT = [
    {
        value: EVENT.COLOUR,
        label: CONTENT.EVENTS.COLOUR,
    },
]

export const EVENTS_OPTIONS_BUTTON = [
    ...EVENTS_OPTIONS_LIGHT,
    {
        value: EVENT.PRESS,
        label: CONTENT.EVENTS.PRESS,
    },
]

export const CONDITION_OPTIONS: { label: string; value: TConditionOption }[] = [
    {
        label: '-',
        value: 'none',
    },
    {
        label: 'true',
        value: true,
    },
    {
        label: 'false',
        value: false,
    },
]

// TYPES
export type TAsset = {
    id: number
    urn: string
    name: string
    assetGroupIDs: number[]
}

export type TRule = {
    isMatch: boolean
    expected: boolean | undefined
    current: boolean
}

export type TCondition = {
    asset?: TAsset
    assetType?: ASSET_TYPE
    button?: BUTTON
    event?: EVENT
    color?: COLOR
    rules?: TRule[]
}

export type TAction = {
    id: string
    name: string
    assetGroupId: number
    enabledAPI: boolean
    rules?: boolean[]
}

export type TAppState = {
    conditions: TCondition[]
    actions?: TAction[]
}

export type TAssetDetails = {
    hardware: {
        type: string
    }
}

export type TConditionOption = boolean | 'none'
