import { useState } from 'react'

import { Button, Spin } from 'antd'

import { TextBox } from '../../../components'
import { CONTENT } from '../../../consts'

import styles from './ActionsForm.module.scss'

export type TActionsFormProps = {
    isLoading?: boolean
    handleAddAction: (ruleId: string, groupId: string) => void
}

export const ActionsForm = ({ isLoading, handleAddAction }: TActionsFormProps) => {
    const [ruleId, setRuleId] = useState<string>()
    const [groupId, setGroupId] = useState<string>()

    const handleClick = () => {
        if (ruleId && groupId) {
            handleAddAction(ruleId, groupId)
            setRuleId(undefined)
            setGroupId(undefined)
        }
    }

    return (
        <div className={styles.Container}>
            {isLoading ? (
                <div className={styles.SpinnerWrapper}>
                    <Spin />
                </div>
            ) : (
                <div className={styles.Content}>
                    <TextBox
                        style={{ width: 350 }}
                        label={CONTENT.ACTIONS.FORM.RULE_ID}
                        handleChange={setRuleId}
                        value={ruleId}
                    />
                    <TextBox
                        style={{ width: 100 }}
                        label={CONTENT.ACTIONS.FORM.ASSET_GROUP_ID}
                        handleChange={setGroupId}
                        value={groupId}
                    />
                    <Button
                        className={styles.Button}
                        type="primary"
                        onClick={handleClick}
                        disabled={!ruleId || !groupId || ruleId === '' || groupId === ''}
                    >
                        {CONTENT.ACTIONS.FORM.ADD}
                    </Button>
                </div>
            )}
        </div>
    )
}
