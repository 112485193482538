import styles from '../Table.module.scss'

export type TImageColumn = {
    key: string
    dataIndex: string
    width: string | number
    title?: string
}

export const ImageColumn = ({ key, dataIndex, width, title = '' }: TImageColumn) => ({
    key,
    dataIndex,
    width,
    title,
    render: (value: string) => {
        return (
            <div className={styles.ImgColumn}>
                <img src={value} alt={value.indexOf('light') === -1 ? 'kis.box' : 'kis.light'} />
            </div>
        )
    },
})
