import React, { useState } from 'react'

import { Select } from 'antd'

import { COLOR, COLORS } from '../../types'
import { getColor, getColorLabel } from './ColorPicker.utils'

import styles from './ColorPicker.module.scss'

export type TColorPickerProps = {
    label: string
    onChange: (color: COLOR) => void
    value?: COLOR
}

export const ColorPicker = ({ label, onChange, value }: TColorPickerProps) => {
    const [open, setOpen] = useState(false)

    return (
        <div className={styles.Container}>
            {label && <label>{label}</label>}
            <Select
                style={{ width: 240 }}
                placeholder={label}
                dropdownRender={() => (
                    <div className={styles.Dropdown}>
                        {COLORS.map((color) => (
                            <div
                                key={`color-${color}`}
                                className={styles.Color}
                                style={{ backgroundColor: getColor(color) }}
                                onClick={() => {
                                    setOpen(false)
                                    onChange(color)
                                }}
                            />
                        ))}
                    </div>
                )}
                open={open}
                onDropdownVisibleChange={(visible) => setOpen(visible)}
                options={COLORS.map((item) => ({
                    label: getColorLabel(item),
                    value: item,
                }))}
                optionLabelProp="label"
                value={value}
                className={styles.Test}
            />
        </div>
    )
}
