import { useState } from 'react'

import axios, { AxiosRequestConfig } from 'axios'

import { COOKIE_NAME } from '../consts'
import { useCookies } from './useCookies'

export const useCallApi = () => {
    const [isLoading, setIsLoading] = useState(false)
    const { cookies } = useCookies()

    const callApi = async <T>(
        config: AxiosRequestConfig,
        setOuterIsLoading?: (newState: boolean) => void,
        makeSilentUpdate = false,
    ) => {
        if (!makeSilentUpdate) setIsLoading?.(true)
        if (!makeSilentUpdate) setOuterIsLoading?.(true)
        try {
            const response = await axios({
                ...config,
                headers: { ...config.headers, Authorization: cookies[COOKIE_NAME] },
            })
            return response?.data as T
        } catch (err: unknown) {
            return undefined
        } finally {
            if (!makeSilentUpdate) setOuterIsLoading?.(false)
            if (!makeSilentUpdate) setIsLoading?.(false)
        }
    }

    return {
        isLoading,
        callApi,
    }
}
