import { Select } from 'antd'
import classNames from 'classnames'

import { TColumn, Table } from '../../../components'
import { ImageColumn } from '../../../components/Table/columns/ImageColumnt'
import { RuleColumn } from '../../../components/Table/columns/RuleColumn'
import { ValueColumn } from '../../../components/Table/columns/ValueColumn'
import { WithDeleteColumn } from '../../../components/Table/columns/WithDeleteColumn'
import { CONTENT } from '../../../consts'
import { CONDITION_OPTIONS, TCondition, TRule } from '../../../types'
import { parseConditionsToConditionRows } from './ConditionsTable.utils'

import styles from './ConditionsTable.module.scss'

export type TConditionsTableProps = {
    conditions?: TCondition[]
    deleteCondition: (index: number) => void
    deleteRule: (index: number) => void
    onRuleChange: (conditionIndex: number, ruleIndex: number, value: TRule['expected']) => void
    rulesCount: number
}

export const ConditionsTable = ({
    conditions,
    deleteCondition,
    deleteRule,
    rulesCount,
    onRuleChange,
}: TConditionsTableProps) => {
    const COLUMNS: TColumn[] = [
        ImageColumn({
            key: 'device-image',
            dataIndex: 'image',
            width: 110,
        }),
        ValueColumn({
            title: CONTENT.CONDITIONS.TABLE.DEVICE_HEADER,
            key: 'device-name',
            dataIndex: 'device',
        }),
        WithDeleteColumn({
            title: CONTENT.CONDITIONS.TABLE.CONDITION_HEADER,
            key: 'condition',
            dataIndex: 'condition',
            deleteAction: deleteCondition,
            className: styles.ConditionColumn,
        }),
    ]

    for (let x = 1; x <= rulesCount; x++) {
        COLUMNS.push(
            RuleColumn({
                index: x,
                key: `conditions-rule`,
                titleClassName: styles.ColumnHeader,
                deleteRule,
                onRuleChange,
                renderCellContent: (
                    value: any,
                    onChange: (newValue: TRule['expected']) => void,
                    isMatch,
                ) => {
                    return (
                        <div
                            className={classNames({
                                [styles.Match]: isMatch && value !== 'none',
                            })}
                        >
                            <Select
                                className={styles.Select}
                                value={value}
                                options={CONDITION_OPTIONS}
                                defaultValue={CONDITION_OPTIONS[0].value}
                                style={{ width: 80 }}
                                onChange={(value) => {
                                    const newValue = value === 'none' ? undefined : value
                                    onChange(newValue)
                                }}
                            />
                        </div>
                    )
                },
            }),
        )
    }

    return (
        <div className={styles.Container}>
            <div className={styles.TableWrapper}>
                <Table columns={COLUMNS} dataSource={parseConditionsToConditionRows(conditions)} />
            </div>
        </div>
    )
}
