import { PropsWithChildren, createContext, useContext, useState } from 'react'

import { INIT_STATE } from '../consts'
import { TAppState, TAsset } from '../types'

export type TMessage = {
    jsontype: string
    type: string
    nodeId: number
    timestamp: string
    info: {
        key: string
        value: string
        timestamp: string
    }
}

export type TContext = {
    appState?: TAppState
    isLogged?: boolean
    isAppLoading?: boolean
    isSyncDone?: boolean
    rulesCount: number
    assets?: TAsset[]
    setIsLogged: (newState: boolean) => void
    setIsAppLoading: (newState: boolean) => void
    setIsSyncDone: (newState: boolean) => void
    setAssets: (newState: TAsset[]) => void
    updateState: (newState: TAppState | undefined, tag: string) => void
}

const Context = createContext<TContext>({
    appState: undefined,
    isLogged: undefined,
    isAppLoading: undefined,
    isSyncDone: undefined,
    assets: [],
    rulesCount: 1,
    setIsLogged: () => {},
    setIsAppLoading: () => {},
    setIsSyncDone: () => {},
    setAssets: () => {},
    updateState: () => {},
})

export const ContextWrapper = ({ children }: PropsWithChildren) => {
    const [appState, setAppState] = useState<TAppState>()
    const [isLogged, setIsLogged] = useState<boolean>()
    const [isAppLoading, setIsAppLoading] = useState<boolean>(true)
    const [isSyncDone, setIsSyncDone] = useState(false)
    const [assets, setAssets] = useState<TAsset[]>()

    const updateState = (newState: TAppState | undefined, updaterTag: string) => {
        if (!newState) {
            setAppState(INIT_STATE)
            return INIT_STATE
        }

        const oldAppState = JSON.stringify(appState)
        const newAppState = JSON.stringify(newState)
        if (oldAppState !== newAppState) {
            console.log(`[${updaterTag}] STATE UPDATED`)
            setAppState(newState)
        }
        return newState
    }

    const value: TContext = {
        appState,
        isLogged,
        isAppLoading,
        rulesCount: appState?.conditions?.[0]?.rules?.length ?? 1,
        setIsLogged,
        setIsAppLoading,
        isSyncDone,
        setIsSyncDone,
        assets,
        setAssets,
        updateState,
    }

    return <Context.Provider value={value}>{children}</Context.Provider>
}

export const useDefaultContext = () => useContext(Context)
